import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./aitalker.css";

const AiTalker = () => {
  return (
    <Container fluid className="py-5 px-md-5 talkerbox">
      <Row className=" ">
        {/* First Column */}

        <Col
          xs={12}
          md={4}
          className=""
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Row style={{ height: "" }} className="my-3 ">
            <Col>
              <div className="box1talker">
                <div className="boxcontent ">
                  <span className="spanclass">WEB BASED CALL</span>

                  <h5 className="mt-4" style={{ lineHeight: "1.5",color: "black" }}>
                    Dive into seamless communication! Our cutting-edge tech
                    ensures unified and effortless calls on your website.
                    Elevate engagement with ease and captivate your audience
                    effortlessly.{" "}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ height: "" }} className="my-5">
            <Col style={{ height: "100%" }}>
              {/* Responsive Box 2 in the second row */}
              <div className="box2">
                <Row>
                  <div className="p-0">
                    {" "}
                    <img
                      src="appbasedcl.png"
                      alt="gg"
                      className="img-fluid"
                    />{" "}
                  </div>
                </Row>
                <Row className="mt-2">
                  <div className="py-4">
                    {" "}
                    <span className="spanclass"> APP BASED CALL</span>
                  </div>
                  <div className="boxcontent pt-2">
                    <h5 style={{ lineHeight: "1.5",color: "black" }}>
                      Elevate sales, marketing, customer onboarding, and support
                      with our app-based call solutions.
                    </h5>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        {/* Second Column */}
        <Col
          xs={12}
          md={4}
          className="p-1"
          style={{ height: "60%" }}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="box2">
            <Row>
              <div className="d-flex justify-content-center p-0">
                {" "}
                <img src="phoncl.png" alt="gg" className="img-fluid" style={{width:"400px",height:"300px"}}/>{" "}
              </div>
            </Row>
            <Row className="">
              <div className="py-4">
                {" "}
                <span className="spanclass">PHONE CALL</span>
              </div>
              <div className="boxcontent ">
                <h5 style={{ lineHeight: "1.5",color: "black" }}>
                  Transform your phone communication with automated handling.
                  Our solution streamlines normal phone calls, providing
                  efficiency and enhancing customer engagement. Revolutionize
                  your phone interactions for a smarter, more responsive
                  approach.
                </h5>
              </div>
            </Row>
          </div>
        </Col>

        {/* Third Column */}
        <Col
          xs={12}
          md={4}
          className="mb-3"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Row style={{ height: "40%" }} className="mb-2 align-items-center">
            <Col>
              {/* Box in the first row of the third column */}
              <div className="box3 p-3 ">
                <Row>
                  {" "}
                  <h1
                    className="text-end"
                    style={{ fontWeight: "bolder",color:"#0A369D" }}
                  >
                    Ai Talker
                  </h1>
                </Row>
                <Row
                  className="d-flex justify-content-end "
                  style={{ textAlign: "end" }}
                >
                  {" "}
                  <div style={{ width: "" }}>
                    <p
                      className="pt-3"
                      style={{ fontWeight: "200", fontSize: "25px",color: "black" }}
                    >
                      Your Voice, Your Brand, <br /> Your Exceptional Customer
                      Experience.
                    </p>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
          <Row
            style={{ height: "50%" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Col>
              {/* Responsive Image in the second row of the third column */}
              <img
                className="img-fluid"
                src="talker.png" // Replace with your image URL
                alt="Responsive"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AiTalker;
