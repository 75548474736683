import React from "react";
import './stage.css'
import { Outlet } from "react-router-dom";
import Sidebar from "../../../components/demo/sidebar/Sidebar";
import Navbar from "../../../components/demo/navbar/Navbar";

const Stage = () =>{

    return(
        <div className="st-container">
            <div className="st-sidebar-container">
                <Sidebar />
            </div>
            <div className="st-navbar-container">
                <Navbar />
            </div>
            <div className="st-content-container">
                <Outlet></Outlet>
            </div>
        </div>
    )

    return (
        <div className="h-100 w-100 container-fluid p-0 st-container">
            <div className="h-100 row st-row">
                <div className="h-100 col-auto col-md-3 col-xl-2 px-sm-2 st-sidebar">
                    <Sidebar></Sidebar>
                </div>
                <div className="h-100 col g-0">
                    <div className="row st-navbar-container">
                        <div className="h-100 w-100 col-12 g-0 st-navbar">
                            <Navbar></Navbar>
                        </div>
                    </div>
                    <div className="h-100 w-100 row">
                        <div className='h-100 w-100 col-12 g-0' style={{backgroundColor:"red"}}>
                            <Outlet></Outlet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Stage