import React from 'react'
import { Container } from 'react-bootstrap'
import Feature1 from './Feature1'
import Feature2 from './Feature2'
import Feature3 from './Feature3'
import Feature4 from './Feature4'
import "./features.css"
import Contact from '../Contact/Contact'



const Features = () => {
  return (
    <Container
      fluid
      className="mt-0 pt-0 mainfeature"
      style={{ color: "gainsboro" }}
    >
      <div className="my-5">
        {" "}
        <Feature1 />
      </div>
      <div className="my-5">
        {" "}
        <Feature2 />
      </div>
      <div className="mt-5">
        {" "}
        <Feature3 />
      </div>
      <div className="mt-1">
        {" "}
        <Feature4 />
      </div>

      {/* <Contact/> */}
    </Container>
  );
}

export default Features