import React, { useState } from 'react'
import Chat from '../../../components/demo/demo-components/chat';

const AichatterHome = () => {
  const [responseFromRasa, setResponseFromRasa] = useState("");

  const handleResponseFromRasa = (response) => {
    console.log("Received response from Rasa:", response);
    setResponseFromRasa(response);
  };
  return (
    <div>
      {" "}
      {/* <h3 className="text-center mt-3"> CHAT BOT</h3> */}
      <Chat onRasaResponse={handleResponseFromRasa} />
    </div>
  );
}

export default AichatterHome