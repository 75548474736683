import React, { useEffect } from 'react'
import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import Features from '../../components/Features/Features';
import Feature1 from '../../components/Features/Feature1';
import Feature2 from '../../components/Features/Feature2';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';

import Map from './map';
import "./home.css"
import AiTalker from './aitalker/AiTalker';
import AiChatter from './aichatter/AiChatter';
import AiEmailer from './aiemailer/AiEmailer';
import SupportPartner from '../../components/Supportpartner/Supportpartner';
import Calender from './map';

const Home = () => {
  useEffect(() => {
    // Set scroll position to the top without any scrolling effect
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="homebg" style={{ backgroundColor: " " }}>
      <Helmet>
        <title>Verbalyze | Home</title>
        <meta
          name="description"
          content="Elevate interactions with Verbalyze: AI for automated calls, emails, chat, and WhatsApp. Transform telemarketing, support, and sales with seamless, personalized onboarding across channels. Say goodbye to traditional methods, embrace intelligent automation. Verbalyze: Redefining customer engagement."
        />
        {/* Add other meta tags as needed */}
      </Helmet>
      <Header />
      <Banner />
      <div className="">
        <AiTalker />
        <AiChatter />
        {/* <AiEmailer /> */}
        <SupportPartner />
<Calender/>
        {/* <Features /> */}
        {/* <Contact /> */}
      </div>
      <Footer />
    </div>
  );
}

export default Home