// import React from "react";
// import { Container } from "react-bootstrap";
// import { blueLogo } from "../../assets"

// const PrivacyPolicy = () => {
//   const containerStyle = {
//     color: "#333", // Dark text color for better contrast
//     background: "linear-gradient(to right, #e0f7fa, #b3e0f2)", // Very light blue gradient background
//     padding: "20px", // Add some padding to improve readability
//     borderRadius: "10px", // Add rounded corners
//   };

//   const headerStyle = {
//     color: "#4285f4", // Google blue color
//   };
//   const logoStyle = {
//     width: "50px", // Set the width of your logo
//     marginRight: "10px", // Add some margin to separate the logo from text
//   };

//   return (
//     <Container className="py-5" style={containerStyle}>
//        <img src={blueLogo} alt="Verbalyze Logo" style={{ ...logoStyle, width: "200px", height: "auto" }} />
//        <p>

//        </p>
//   <h2 style={headerStyle}>Privacy Policy for Verbalyze</h2>

// </Container>
//   );
// };

// export default PrivacyPolicy;

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./policy.css"



const PrivacyPolicyPage = () => {
  useEffect(() => {
    // Set scroll position to the top without any scrolling effect
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />{" "}
      <div className="p-5 policybg">
        <Container
          className="py-5 text-center policybox"
          style={{ borderRadius: "30px" }}
        >
          <h2 className="my-3">Privacy Policy</h2>
          <Row className="justify-content-center">
            <Col md={10} className="px-5">
              <p className="text-center" style={{ color: "#7040d7" }}>
                Last Updated: 10-01-24
              </p>
              <p className="text-center">
                Thank you for choosing Verbalyze! This Privacy Policy outlines
                how we collect, use, disclose, and safeguard your information
                when you use our services.
              </p>{" "}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10}>
              {/* <h3 className="text-center">Privacy Policy Of Verbalyze</h3> */}

              <div className="text-start p-5">
                <div className="subdiv my-5">
                  <h4 style={{ color: "#7040d7" }}>Information We Collect:</h4>
                  <p>
                    We may collect personal information, including but not
                    limited to names, contact details, and user preferences, to
                    enhance your experience with Verbalyze.
                  </p>
                </div>
                <div className="subdiv my-5">
                  <h4 style={{ color: "#7040d7" }}>
                    How We Use Your Information:
                  </h4>
                  <p>
                    We use collected information to provide, improve, and
                    personalize our services. This includes communication,
                    troubleshooting, and delivering relevant content.
                  </p>
                </div>
                <div className="subdiv my-5">
                  <h4 style={{ color: "#7040d7" }}>Information Sharing:</h4>
                  <p>
                    We do not sell or rent your personal information to third
                    parties. Your data is shared only as necessary to provide
                    our services or comply with legal obligations.
                  </p>
                </div>
                <div className="subdiv my-5">
                  {" "}
                  <h4 style={{ color: "#7040d7" }}>Security:</h4>
                  <p>
                    We implement robust security measures to protect your
                    information. However, no method of transmission over the
                    internet or electronic storage is entirely secure, and we
                    cannot guarantee absolute security.
                  </p>
                </div>
                <div className="subdiv my-5">
                  {" "}
                  <h4 style={{ color: "#7040d7" }}>
                    Cookies and Similar Technologies:
                  </h4>
                  <p>
                    We use cookies and similar technologies to enhance your
                    experience, analyze usage patterns, and customize content.
                  </p>
                </div>
                <div className="subdiv my-5">
                  <h4 style={{ color: "#7040d7" }}>Children's Privacy:</h4>
                  <p>
                    Verbalyze is not intended for individuals under the age of
                    13. We do not knowingly collect personal information from
                    children.
                  </p>
                </div>
                <div className="subdiv my-5">
                  {" "}
                  <h4 style={{ color: "#7040d7" }}>
                    Changes to Privacy Policy:
                  </h4>
                  <p>
                    We reserve the right to update our Privacy Policy. We will
                    notify you of any changes by posting the new policy on our
                    website.
                  </p>
                </div>
                <div className="subdiv my-5">
                  <h4 style={{ color: "#7040d7" }}>Contact Us:</h4>
                  <p>
                    If you have any questions or concerns regarding our Privacy
                    Policy, please contact us at{" "}
                    <a href="mailto:services@verbalyze.in">
                      services@verbalyze.in
                    </a>
                  </p>
                </div>
                <div className="subdiv my-5">
                  <p>
                    By using Verbalyze, you agree to the terms outlined in this
                    Privacy Policy.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="p-5">
          <Col md={{ span: 8, offset: 4 }}></Col>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
