import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";

import "./Feature2.css";

const Feature4 = () => {
  return (
    <Container className="my-container  py-5">
      <Row
        className="align-items-center  py-5 justify-content-center"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        {/* Right column with responsive image for small screens */}
        <Col lg={6} md={12} className="order-sm-2">
          <div className="image-column">
            <Image src="6.png" alt="Responsive Image" fluid />
          </div>
        </Col>
        {/* Left column with title and paragraph text for small screens */}
        <Col lg={6} md={12} data-aos="fade-up" data-aos-duration="2000">
          <div className="text-column">
            <h2 className="mb-4 " style={{ color: "" }}>
              Educating Customers & Stakeholders
            </h2>
            <p className="text-start" style={{ fontSize: "20px" }}>
              Verbalyze's AI brilliance ensures that each interaction is
              uniquely crafted, providing valuable insights and knowledge
              specific to individual needs. By deciphering emotions, we not only
              educate but also enhance emotional intelligence.
            </p>
            <div className="text-start">
              <ul className="headul">
                <li>Tailored Learning Journeys.</li>
                <li>Emotional Intelligence in Training. </li>
                <li>Interactive Q&A Sessions.</li>
                <li>Gamified Learning Experiences.</li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Feature4;
