

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./aichatter.css";

const AiChatter = () => {
  return (
    <Container fluid className="py-5 px-md-5  chatterbox">
      <Row className="py-5">
        {/* First Column */}

        <Col
          xs={12}
          md={4}
          className=""
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Row>
            <div className="box3 p-3 my-5 align-items-center text-center">
              <Row>
                {" "}
                <h1
                  className=""
                  style={{
                    fontWeight: "bolder",
                    color:"#0A369D",
                    fontSize: "50px",
                  }}
                >
                  Ai Chatter
                </h1>
              </Row>
              <Row className="d-flex justify-content-center " style={{}}>
                {" "}
                <div style={{ width: "" }}>
                  <p
                    className="pt-3"
                    style={{ fontSize: "25px", fontWeight: "200" }}
                  >
                    Elevate Your Words, Elevate Your Connection - Intelligent
                    Conversations at Your Fingertips.{" "}
                  </p>
                </div>
              </Row>
            </div>
          </Row>
          <Row className="my-5">
            <Col style={{ height: "100%" }}>
              <div className="box2">
                <Row>
                  <div>
                    {" "}
                    <img src="aiwhatspp.png" alt="gg" className="img-fluid" />{" "}
                  </div>
                </Row>
                <Row className="mt-2">
                  <div className="py-4">
                    {" "}
                    <span className="spanclass"> AI WHATSAPP</span>
                  </div>
                  <div className="boxcontent pt-2">
                    <h5 style={{ lineHeight: "1.5", color: "black" }}>
                      Your 24/7 WhatsApp Assistant! Break language barriers, get
                      instant help, and make every chat count with our
                      intelligent WhatsApp Bot.
                    </h5>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        {/* Second Column */}

        <Col
          xs={12}
          md={4}
          className="mb-3 p-3"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Row style={{ height: "60%" }} className="py-3">
            <Col style={{ height: "100%" }}>
              <div className="box2">
                <Row>
                  <div>
                    {" "}
                    <img src="appbasedchat.png" alt="gg" className="img-fluid" />{" "}
                  </div>
                </Row>
                <Row className="mt-2">
                  <div className="py-4">
                    {" "}
                    <span className="spanclass"> APP BASED CHATTER</span>
                  </div>
                  <div className="boxcontent pt-2">
                    <h5 style={{ lineHeight: "1.5",color: "black" }}>
                      Smart, Seamless, Interactive! Elevate your app experience
                      with our App-Based Chatter—your intelligent companion for
                      personalized interactions.
                    </h5>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        {/* Third Column */}
        <Col
          xs={12}
          md={4}
          className=""
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Row style={{ height: "" }} className="my-5">
            <Col>
              {/* Responsive Box 1 in the first row */}
              <div className="box1">
                {/* <div className="my-1"> </div> */}
                <div className="boxcontent ">
                  <span className="spanclass">WEB BASED CHATTER</span>

                  <h5 className="mt-5" style={{ lineHeight: "1.5", color: "black" }}>
                    Whether you're exploring website for information or seeking
                    assistance, our web-chatter is just a click away. Engage in
                    natural, context-aware conversations, get instant support,
                    and discover a new level of interaction{" "}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className="d-flex align-items-center  justify-content-center"
            style={{ height: "" }}
          >
            <Col style={{ height: "100%", width: "100%" }}>
              {/* Responsive Image in the second row of the third column */}
              <img
                className="img-fluid"
                src="chatter.png" // Replace with your image URL
                alt="Responsive"
                style={{ height: "90%", width: "100%" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AiChatter;
