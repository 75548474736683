import React, { useEffect } from "react";
import Features from "../../components/Features/Features";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import SolutionBanner from "./solutionbanner";

const Solutions = () => {
  useEffect(() => {
    // Set scroll position to the top without any scrolling effect
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="" style={{ backgroundColor: " #170936" }}>
      <Helmet>
        <title>Verbalyze | Solutions</title>
        <meta
          name="description"
          content="Elevate interactions with Verbalyze: AI for automated calls, emails, chat, and WhatsApp. Transform telemarketing, support, and sales with seamless, personalized onboarding across channels. Say goodbye to traditional methods, embrace intelligent automation. Verbalyze: Redefining customer engagement."
        />
      </Helmet>
          <Header />
          <SolutionBanner/>
      <div className="mt-5">
        <Features />
        {/* <Contact /> */}
      </div>
      <Footer />
    </div>
  );
};

export default Solutions;
