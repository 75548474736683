import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Journey = () => {
  return (
    <Container className="py-5 mt-5">
      <Row
        className="justify-content-center jrow"
        style={{ borderRadius: "20px" }}
      >
        {/* Left Column */}
        <Col
          xs={12}
          md={6}
          className=" p-5 align-items-center"
          style={{
            borderRadius: "20px",
            color: "gainsboro",
          }}
        >
          <h2>Our Journey</h2>
          <p className="my-5" style={{fontSize:"20px",lineHeight:"1.5"}}>
            From modest origins to pioneering AI-driven startup, Verbalyze's
            journey transforms engagement. Committed to revolutionizing
            business-audience connections, each stride reshapes the engagement
            landscape.
          </p>
          <Button variant="primary">Join us</Button>
        </Col>

        {/* Right Column */}
        <Col xs={12} md={6} className="p-3 ">
          <img
            src="ourjourney.png" // Replace with the actual image URL
            alt="Image"
            className="img-fluid" // To make the image responsive
            style={{ borderRadius: "20px" ,height:"400px",width:"80%"}}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Journey;
