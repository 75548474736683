import React, { useState, useEffect } from "react";
import "./email.css";
import { FaBuromobelexperte, FaPaperPlane, FaPen } from "react-icons/fa";
import { Col, Container, Form, Row } from "react-bootstrap";
import { MdEmail } from "react-icons/md";
import { BsFillPersonFill, BsX } from "react-icons/bs";
import { gmail } from "../../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const Email = ({ onRasaResponse }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isComposeMailOpen, setIsComposeMailOpen] = useState(true);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      const userMessage = { text: newMessage, user: "user" };
      setChatMessages((prevChatMessages) => [...prevChatMessages, userMessage]);
      setNewMessage("");

      try {
        const requestBody = {
          prompt: newMessage,
          verbalyze_api_key: "ezi10xemail",
          user_id: "client_1",
          convo_type: "mail",
          parameters: {
            temperature: 0.1,
          },
        };

        const response = await fetch(
          "https://swar-samwad-2.azurewebsites.net/webhooks/rest/webhook",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          handleBotResponse(responseData);
        } else {
          handleBotError("Chatbot error");
        }
      } catch (error) {
        handleBotError("Error communicating with chatbot");
        console.error("Error sending request to chatbot:", error);
      }
    }
  };

  const handleBotResponse = (responseData) => {
    if (Array.isArray(responseData)) {
      responseData.forEach((item) => {
        const botMessage = { text: item, user: "bot" };
        setChatMessages((prevChatMessages) => [
          ...prevChatMessages,
          botMessage,
        ]);
      });
      onRasaResponse(responseData); // Pass the entire responseData to onRasaResponse
    } else {
      // Handle single response
      const botMessage = { text: responseData, user: "bot" };
      setChatMessages((prevChatMessages) => [...prevChatMessages, botMessage]);
      onRasaResponse([responseData]); // Wrap responseData in an array before passing to onRasaResponse
    }
  };

  useEffect(() => {
    // Initialize chat with a static message from bot
    const initialBotMessage = "Compose a mail to get response";
    const botMessage = { text: initialBotMessage, user: "bot" };
    setChatMessages([botMessage]);
  }, []);

  const handleBotError = (errorMessage) => {
    const error = { text: errorMessage, user: "bot" };
    setChatMessages((prevChatMessages) => [...prevChatMessages, error]);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the Enter key from adding a new line in the input field
      handleSendMessage(); // Trigger sending the message
    }
  };

  const handleComposeMailClick = () => {
    setIsComposeMailOpen(true);
  };

  const handleCancelComposeMail = () => {
    setIsComposeMailOpen(false);
  };

  return (
    <Container fluid className="align-items-center p-4 pt-2">
      <Row className="mb-2">
        <Col xs={12}>
          <div className="gmail-heading">
            <img
              src={gmail}
              alt="Gmail Logo"
              className="gmail-logo"
              style={{ width: "25px", height: "25px", marginRight: "10px" }} // Add margin-right for spacing
            />
            <h4
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                fontWeight:"bold"
              }}
            >
              Mail
            </h4>
            <button
              onClick={handleComposeMailClick}
              className="compose-mail-btn"
            >
              <FaPen style={{ marginRight: "5px" }} /> Compose
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={8} md={12} lg={12}>
          <div className="email-container">
            <div className="email-messages" id="email-messages-container">
              {chatMessages.map((message, index) => (
                <React.Fragment key={index}>
                  <div className={`email-message ${message.user}`}>
                    {message.user === "user" ? (
                      <span style={{ display: "flex" }}>
                        {" "}
                        <BsFillPersonFill className="profile-icon" />{" "}
                        <p style={{ color: "grey", marginBottom: "2px" }}>
                          testuser@gmail.com
                        </p>
                      </span>
                    ) : (
                      <span style={{ display: "flex" }}>
                        {" "}
                        <MdEmail className="email-icon" />{" "}
                        <p style={{ color: "grey", marginBottom: "2px" }}>
                          verbalyze@verbalyze.in
                        </p>
                      </span>
                    )}
                    <div className="message-text">{message.text}</div>
                  </div>
                  {index !== chatMessages.length - 1 && (
                    <hr className="email-divider" />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      {isComposeMailOpen && (
        <div className="compose-mail-card">
          <div className="compose-mail-header">
            <p className="m-0" style={{ fontSize: "15px" }}>
              &nbsp; New Message
            </p>
            <BsX
              size={24}
              onClick={handleCancelComposeMail}
              style={{ cursor: "pointer", fontWeight: "bold" }}
            />
          </div>
          <div className="compose-mail-body">
            {/* <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Compose your message..."
            /> */}
            <span style={{ display: "flex", fontSize: "15px", margin: "5px" }}>
              {" "}
              <BsFillPersonFill className="profile-icon" />{" "}
              <p style={{ color: "grey", marginBottom: "2px" }}>
                verbalyze@verbalyze.in
              </p>
            </span>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={13}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Enter your message..."
                style={{ padding: "10px" }}
              />
            </Form.Group>
            <div className="d-flex justify-content-start mt-2 pl-5 pt-2">
              <button
                onClick={handleSendMessage}
                className="send-compose-mail-btn"
              >
                Send
              </button>
              <button
                onClick={handleCancelComposeMail}
                className="cancel-compose-mail-btn mr-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Email;
