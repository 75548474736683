import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./banner.css";
import { initAnimation } from "./BannerAnimation";

const greetings = [
  "Hello", "Hola", "Bonjour", "Hallo", "Ciao", "Olá",
  "مرحبا", "नमस्ते", "হ্যালো", "ہیلو", "ਸਤ ਸ੍ਰੀ ਅਕਾਲ", "வணக்கம்", "హలో", "नमस्कार", "નમસ્તે", "ഹലോ",
  "ನಮಸ್ಕಾರ", "Merhaba", "Xin chào", "สวัสดี", "Habari", "Hallo", "Γειά", "Cześć", "Привіт", "Salut",
  "Здравствуйте", "你好", "こんにちは", "안녕하세요", "Salam", "Sawubona"
];

const Banner = () => {
  const [currentGreetingIndex, setCurrentGreetingIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentGreetingIndex((prevIndex) => (prevIndex + 1) % greetings.length);
    }, 1000);

    initAnimation();

    return () => clearInterval(interval);
  }, []);

  return (
    <Container fluid className="banner">
      <canvas id="drawing_canvas"></canvas>
      <div className="greeting-container">
        <h1 className="banner-greeting">{greetings[currentGreetingIndex]}</h1>
        
        <p className="banner-subtext">Ai Talker: 24/7 AI-powered call handling for Enterprises.</p>
        <p className="banner-highlight"><em># No call goes unanswered.</em></p>
      </div>
    </Container>
  );
};

export default Banner;
