import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "../../components/Banner/banner.css";



const SolutionBanner = () => {
  const containerStyle = {
    backgroundImage: 'url("solnbg.jpg")', // Replace with your background image URL
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <Container fluid style={containerStyle}>
      <Row>
        {/* Left Column with Responsive Image */}
        <Col xs={12} md={6}>
          <Image
            src="soln.png" // Replace with your image URL
            alt="Her Image"
            fluid
            data-aos="fade-up"
            data-aos-duration="2000"
          />
        </Col>

        {/* Right Column with Heading and Paragraph */}
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-end p-5 "
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="p-5 " style={{ color: "white" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                textAlign: "end",
              }}
              className="d-flex justify-content-end"
            >
              {" "}
              <h1>
                OUR <br /> SOLUTIONS
              </h1>
            </div>
            <p
              className="my-5"
              style={{
                fontSize: "20px",
                textAlign: "justify",
                lineHeight: "35px",
                color: "gainsboro",
              }}
            >
              Verbalyze unifies all communication channels, streamlining voice
              calls, WhatsApp, emails, and more. Tailored for sales, marketing,
              and customer support, it empowers businesses with advanced
              conversational AI for personalized and efficient interactions.
              Experience the future of customer engagement with Verbalyze!
            </p>
            {/* <button>Get demo</button> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SolutionBanner;
