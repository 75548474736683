import React, { useState, useEffect } from 'react';
import eventEmitter from './eventEmitter';
import "./democomponent.css"

  const DashboardTextToSpeech = ({
    responseFromRasa,
    onAudioPlaybackComplete,
  }) => {
    const [audioInstance, setAudioInstance] = useState(null);
    const [lastResponse, setLastResponse] = useState("");
    const [audioBytes, setAudioBytes] = useState(null);
    const [error, setError] = useState(null);

    const playAudio = (audioBytes) => {
      try {
        if (audioInstance) {
          audioInstance.pause();
          audioInstance.currentTime = 0; // Set the current playback time to the beginning
        }

        const audioBlob = new Blob(
          [Uint8Array.from(atob(audioBytes), (c) => c.charCodeAt(0))],
          { type: "audio/wav" }
        );
        const audioUrl = URL.createObjectURL(audioBlob);

        const newAudioInstance = new Audio(audioUrl);
        setAudioInstance(newAudioInstance);

        newAudioInstance.play().catch((error) => {
          console.error("Error playing audio:", error);
        });

        newAudioInstance.addEventListener("ended", () => {
          onAudioPlaybackComplete();
          eventEmitter.emit("audioPlaybackComplete"); // Emit an event when audio playback is complete
        });
      } catch (error) {
        console.error("Error creating audio blob:", error);
      }
    };

    useEffect(() => {
      const speakingStartHandler = () => {
        if (audioInstance) {
          audioInstance.pause();
          audioInstance.currentTime = 0; // Set the current playback time to the beginning
        }
      };

      const speakingEndHandler = () => {
        if (responseFromRasa && responseFromRasa !== lastResponse) {
          setLastResponse(responseFromRasa);
          handleTestTTS();
        }
      };

      eventEmitter.on("speakingStart", speakingStartHandler);
      eventEmitter.on("speakingEnd", speakingEndHandler);

      return () => {
        eventEmitter.off("speakingStart", speakingStartHandler);
        eventEmitter.off("speakingEnd", speakingEndHandler);
      };
    }, [responseFromRasa, audioInstance, lastResponse]);

    useEffect(() => {
      if (responseFromRasa && responseFromRasa !== lastResponse) {
        setLastResponse(responseFromRasa);
        eventEmitter.emit("speakingStart"); // Emit an event when TTS starts speaking
        handleTestTTS();
      }
    }, [responseFromRasa]);

    const handleTestTTS = async () => {
      const textToSpeechApiUrl =
        "https://texttospeech.googleapis.com/v1/text:synthesize";
      const apiKey = "AIzaSyCSEGBn4MmZInj7cxYrhja1QEcpU98b1Ow"; // Replace with your API key

      const requestBody = {
        input: {
          text: responseFromRasa || "Default text if responseFromRasa is empty",
        },
        voice: {
          languageCode: "en-IN",
          name: "en-IN-Neural2-A",
          ssmlGender: "FEMALE",
        },
        audioConfig: {
          audioEncoding: "LINEAR16",
        },
      };

      try {
        const response = await fetch(`${textToSpeechApiUrl}?key=${apiKey}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch audio: ${response.statusText}`);
        }

        const responseData = await response.json();
        const audioBytes = responseData.audioContent;

        setAudioBytes(audioBytes);
        setError(null);

        // Play the audio
        playAudio(audioBytes);
      } catch (error) {
        console.error("Error fetching audio:", error);
        setAudioBytes(null);
        setError("Error fetching audio: " + error.message);
      }
    };
    return (
      <div>
        <button id="invisible-button" onClick={handleTestTTS}>
          Test TTS
        </button>
      </div>
    );
  };

export default DashboardTextToSpeech;