import React from 'react'
import { Container, Row, Col, Button, Image } from "react-bootstrap";

import "./Feature2.css"

const Feature2 = () => {
  return (
    <Container className="my-container my-2">
      <Row className="align-items-center   justify-content-center">
        {/* Right column with responsive image for small screens */}
        <Col
          lg={6}
          md={12}
          className="order-sm-2 "
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="image-column">
            <Image src="4.png" alt="Responsive Image" fluid />
          </div>
        </Col>
        <Col lg={6} md={12} data-aos="fade-up" data-aos-duration="2000">
          <div className="text-column">
            <h2 className="mb-4 " style={{ color: "" }}>
              Onboarding New Customers
            </h2>
            <p className="text-start" style={{ fontSize: "20px" }}>
              Verbalyze revolutionizes onboarding by seamlessly integrating into
              welcoming processes, guiding new customers through product
              orientation and setup.
            </p>
            <div className="text-start">
              <ul className="headul">
                <li>Simplifies user account setup.</li>
                <li>Aiding in configuration. </li>
                <li>Enhancing the overall onboarding journey.</li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Feature2