import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./subs.css"
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';


const Subscription = () => {
  useEffect(() => {
    // Set scroll position to the top without any scrolling effect
    window.scrollTo(0, 0);
  }, []);
   const topics = [
     "Voice Cloning",
     "Sentiment Analysis",
     "Intent Recognition",
     "Emotion Detection",
     "Voice Recognition",
     "Speech Synthesis",
     "Voice Biometric",
     "Feedback Collection",
     "Automated Surveys",
     "Market Research",
     "Predictive Analytics for Sales",
     "Predictive Customer Support",
     "Location-Based Services",
     "Optimized Response Time",
     "Load Balancing",
     "Auto-Scaling",
     "Error Handling",
     "APIs Integration",
   ];
const topics2 = [
  "Automatic Call Distribution",
  "Gen. Ai Interactive Voice Response",
  "Call Monitoring",
  "Call Transfer",
  "24X7 Live",
  "FAQ Handling",
  "Troubleshooting",
  "Order Tracking",
  "Appointment Scheduling",
  "Product Recommendations",
  "Billing & Payments",
  "Returns & Refunds",
  "Complaint Handling",
  "Reporting and Analytics",
  "Average Handling Time",
  "Fast Call Resolution",
  "Conversation Analytics",
  "Performance Metrics",
  "User Journey Mapping",
  "Multi-Channel Support",
  "Email Handling",
  "Chat Support",
  "Callback Request",
  "Knowledge Base Integration",
  "PCI DSS Compliance",
  "HIPAA Compliance",
  "DPDPA",
  "GDPR Compliance",
  "Audit Trails",
  "CRM Integration",
  "Database Integration",
  "Third-Party Integration",
  "User Authentication",
];
const topics3 = [
  "WhatsApp Integration",
  "In-App Chat Integration",
  "SMS Messaging",
  "Call Recording",
  "Background Noise Cancellation",
  "Voice Pitch Control",
  "Push Notifications",
  "Multi-lingual Support",
  "Cultural Sensitivity",
  "User Onboarding",
  "Bot Personality Customization",
  "E-commerce Integration",
  "Legacy System Integrations",
  "Omnichannel Integration",
  "Data Retrieval",
  "Customized Dashboard",
  "Real-Time Reporting",
  "Automated Reporting",
  "Historical Reporting",
  "Task Automation",
  "Lead Classification",
  "Text Summarization",
  "Data Encryption",
  "Data Backup & Recovery",
  "Personalization",
  "User Profiling",
  "Fallback Mechanism",
  "Continuous Learning",
  "Time zone Awareness",
  "Call Scripting",
  "Customer Insight",
  "Content Generation",
  "Appointment Reminder",
];


  
  
    return (
      <div style={{ background: "gainsboro" }} className="">
        <Helmet>
          <title>Verbalyze | Subscription</title>
          <meta
            name="description"
            content='Explore our subscription plans for exclusive benefits and premium services. Choose the perfect subscription to elevate your experience. Enjoy seamless access, personalized features, and unmatched value. Subscribe now for a new level of convenience and excellence.'
          />
          {/* Add other meta tags as needed */}
        </Helmet>
        <Header />
        <Container fluid className="">
          <h2 className="text-center mt-4 mb-5" style={{ color: "#4E54C8" }}>
Features          </h2>
          <Row className="justify-content-center p-2">
            <Col
              md={3}
              className="col1 d-flex flex-column"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <h3>Enterprise</h3>
                <h5 className="my-3">with all basic and premium features</h5>
                <ul className="subsul">
                  {topics.map((topic, index) => (
                    <li key={index} style={{ fontSize: "15px" }}>
                      {" "}
                      <span>
                        <img
                          src="tick.png"
                          alt=""
                          style={{ width: "15px", height: "15px" }}
                        />
                      </span>{" "}
                      &nbsp; &nbsp;
                      {topic}
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="mt-auto">
                <button className="chooseplanbtn1">Choose Plan</button>
              </div> */}
            </Col>

            <Col
              md={3}
              className="col3 d-flex flex-column"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div>
                <h2>Premium</h2>
                <h5 className="my-3">with all Basic features</h5>
                <ul className="subsul3">
                  {topics3.map((topic, index) => (
                    <li key={index} style={{ fontSize: "15px" }}>
                      {" "}
                      <span>
                        <img
                          src="tick.png"
                          alt=""
                          style={{ width: "15px", height: "15px" }}
                        />
                      </span>{" "}
                      &nbsp; &nbsp;
                      {topic}
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="mt-auto">
                <button className="chooseplanbtn3">Choose Plan</button>
              </div> */}
            </Col>
            <Col
              md={3}
              className="col2 d-flex flex-column"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <h2>Basic</h2>
                <h5 className="my-3">all basic features</h5>{" "}
                <ul className="subsul2" style={{ color: "gray" }}>
                  {topics2.map((topic, index) => (
                    <li key={index} style={{ fontSize: "15px" }}>
                      {" "}
                      <span>
                        <img
                          src="tick.png"
                          alt=""
                          style={{ width: "15px", height: "15px" }}
                        />
                      </span>{" "}
                      &nbsp; &nbsp;
                      {topic}
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="mt-auto">
                <button className="chooseplanbtn2">Choose Plan</button>
              </div> */}
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
};

export default Subscription;
 