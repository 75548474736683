import React, { useEffect, useState } from 'react'
import SpeechToText from '../../../components/demo/demo-components/stt-dashboard';
import TextToSpeech from '../../../components/demo/demo-components/tts';
import eventEmitter from '../../../components/demo/demo-components/eventEmitter';
import "./aitalker.css"
import A from '../A';
const AiTalkerHome = () => {
   useEffect(() => {
     // Set scroll position to the top without any scrolling effect
     window.scrollTo(0, 0);
   }, []);
  return (
    <div className='p-2'>
      
      <A/>
    
    </div>
  );
}

export default AiTalkerHome; 