// import React from 'react'
// import "./supportpartner.css"
// import MarqueeImages from './MarqueeImages';
// import { Col, Container, Row } from 'react-bootstrap';
// import Brand from './Brand';
// import Marquee from './new';


// const Supportpartner = () => {
//   return (
//     <Container fluid className='my-5 py-5'>
//       <Row className='mt-5 pt-5'>
//         <Col>
//           <h2 style={{color:"wheat",textAlign:"center"}} className='mb-5 mt-5'>Our Support Partners</h2>
//         </Col>
//       </Row>
//       <Row className=''>
//         <Col>
//           <MarqueeImages />
//           {/* <Brand /> */}
//           {/* <Marquee/> */}
//         </Col>
//       </Row>
//     </Container>
//   );
// }

// export default Supportpartner

import React from "react";
import "./supportpartner.css";
import { img1, img2, img3, img4, img5, img6 } from "../../assets";
import { Container } from "react-bootstrap";


// const partnerLogos = [
//   "gc1.png",
//   "logo-img2.png",
//   "mongo.png",
//   "logo-img4.png",
//   "logo-img5.png",
//   "nvidia-logo.png",
// ];
const partnerLogos = [img1, img2, img4, img5, img6, img3];


const SupportPartner = () => {

  return (
    <Container fluid className="supportbg py-5">
      {" "}
      <div className="logos">
        <h2
          style={{ color: "gainsboro", textAlign: "center" }}
          className="mb-5 mt-5"
        >
          Our Support Partners
        </h2>
        <div className="logos-slide">
          {partnerLogos.map((logo, index) => (
            <img
              src={logo}
              alt={`Logo ${index}`}
              key={index}
              className="logo-img"
            />
          ))}
        </div>
        <div className="logos-slide">
          {partnerLogos.map((logo, index) => (
            <img
              src={logo}
              alt={`Logo ${index}`}
              key={index}
              className="logo-img"
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default SupportPartner;