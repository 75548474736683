// import React, { useState, useEffect } from 'react';
// import eventEmitter from './eventEmitter';




// function SpeechToText({ onRasaResponse, onStopRecognition }) {
//   const [transcript, setTranscript] = useState('');
//   const [listening, setListening] = useState(false);
//   const [isSpeaking, setIsSpeaking] = useState(false);
//   const [responseText, setResponseText] = useState('');

//   let recognition = null;

//   const toggleRecognition = () => {
//     if (listening) {
//       stopRecognition();
//     } else {
//       startRecognition('en-US');
//     }
//   };

//   const startRecognition = (languageCode) => {
//     recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
//     recognition.lang = languageCode;
//     recognition.continuous = true;

//     recognition.onstart = () => {
//       setListening(true);
//     };

//     recognition.onresult = (event) => {
//       const currentTranscript = event.results[event.results.length - 1][0].transcript;
//       setTranscript(currentTranscript);
//       if (!isSpeaking) {
//         sendTranscriptToRasa(currentTranscript);
//       }
//       console.log(currentTranscript);
//     };

//     recognition.onend = () => {
//       setListening(false);
//       if (!isSpeaking) {
//         startRecognition(languageCode); // Restart recognition for continuous listening
//       }
//       eventEmitter.emit('recognitionEnd'); // Emit an event when recognition ends
//     };

//     recognition.start();
//   };

//   const stopRecognition = () => {
//     if (recognition) {
//       recognition.stop();
//       setListening(false);
//       onStopRecognition(); // Notify parent component about stopping recognition
//     }
//   };

//   const sendTranscriptToRasa = async (text) => {
//     try {
//       const requestBody = {
//         prompt: text,
//         verbalyze_api_key: "grns1711" // Add your API key here
//       };
  
//       const response = await fetch("https://swar-samwad-2.azurewebsites.net/webhooks/rest/webhook", {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//            'Access-Control-Allow-Origin': '*',
//         },
//         body: JSON.stringify(requestBody),
//       });
  
//       if (response.ok) {
//         const responseData = await response.json();
  
//         console.log("Response from Rasa:", responseData); // Log the entire response for debugging
//         if (responseData && responseData.length > 0) {
//           setResponseText(responseData);
//           onRasaResponse(responseData);
//         }
  
//       } else {
//         console.error('Failed to fetch data from Rasa API');
//       }
//     } catch (error) {
//       console.error('Error sending request to Rasa API', error);
//     }
//   };
  
  

//   useEffect(() => {
//     const speakingStartHandler = () => {
//       setIsSpeaking(true);
//     };

//     const speakingEndHandler = () => {
//       setIsSpeaking(false);
//     };

//     eventEmitter.on('speakingStart', speakingStartHandler);
//     eventEmitter.on('speakingEnd', speakingEndHandler);

//     return () => {
//       eventEmitter.off('speakingStart', speakingStartHandler);
//       eventEmitter.off('speakingEnd', speakingEndHandler);
//     };
//   }, []);

//   return (
//     <div>
//       <button onClick={toggleRecognition} disabled={listening} className="speech-button blue round-button">
//         {listening ? 'Listening...' : 'Talk to Shriram Properties'}
//       </button>
//     </div>
//   );
// }

// export default SpeechToText;


import React, { useState, useEffect } from 'react';  
// import {mic, mic1} from '';
import eventEmitter from './eventEmitter';
// import { mic1,mic } from './images';
import "./democomponent.css";
import "./stt-dashboard.css"
import { mic, mic1 } from '../../../assets';

function DashboardSpeechToText({ onRasaResponse, onStopRecognition }) {
  const [transcript, setTranscript] = useState('');
  const [listening, setListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [responseText, setResponseText] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isTTSpeaking, setIsTTSpeaking] = useState(false);

  let recognition = null;

  const toggleRecognition = () => {
    if (listening) {
      stopRecognition();
    } else {
      startRecognition('en-US');
    }
  };

  const startRecognition = (languageCode) => {
    recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = languageCode;
    recognition.continuous = true;

    recognition.onstart = () => {
      setListening(true);
    };

    recognition.onresult = (event) => {
      const currentTranscript = event.results[event.results.length - 1][0].transcript;
      setTranscript(currentTranscript);
      if (!isSpeaking && !isTTSpeaking) {
        sendTranscriptToRasa(currentTranscript);
      }
      console.log(currentTranscript);
    };

    recognition.onend = () => {
      setListening(false);
      if (!isSpeaking) {
        startRecognition(languageCode); // Restart recognition for continuous listening
      }
      eventEmitter.emit('recognitionEnd'); // Emit an event when recognition ends
    };

    recognition.start();
  };

  const stopRecognition = () => {
    if (recognition) {
      recognition.stop();
      setListening(false);
      onStopRecognition(); // Notify parent component about stopping recognition
    }
  };

  const sendTranscriptToRasa = async (text) => {
    try {
      const requestBody = {
        prompt: text,
        verbalyze_api_key: "verbalyze_org" // Add your API key here
      };

      const response = await fetch("https://swar-samwad-2.azurewebsites.net/webhooks/rest/webhook", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        console.log("Response from Rasa:", responseData); // Log the entire response for debugging
        if (responseData && responseData.length > 0) {
          setResponseText(responseData);
          onRasaResponse(responseData);
        }

      } else {
        console.error('Failed to fetch data from Rasa API');
      }
    } catch (error) {
      console.error('Error sending request to Rasa API', error);
    }
  };

  useEffect(() => {
    const speakingStartHandler = () => {
      setIsSpeaking(true);
      setIsTTSpeaking(true);
    };

    const speakingEndHandler = () => {
      setIsSpeaking(false);
      setIsTTSpeaking(false);
      if (responseText && responseText !== transcript) {
        setResponseText(transcript);
        sendTranscriptToRasa(transcript);
      }
    };

    eventEmitter.on('speakingStart', speakingStartHandler);
    eventEmitter.on('speakingEnd', speakingEndHandler);

    return () => {
      eventEmitter.off('speakingStart', speakingStartHandler);
      eventEmitter.off('speakingEnd', speakingEndHandler);
    };
  }, [isTTSpeaking, responseText, transcript]);

  return (
    <div>

      <button
        onClick={toggleRecognition}
        disabled={listening}
        className="speech-button2 blue round-button"
      >
        <img
          src={buttonClicked ? mic1 : mic}
          alt="Microphone Icon"
          style={{
            width: "7.25rem",
            height: "7.25rem",
            objectFit: "contain",
          }}
        />
        {/* {listening ? 'Listening...' : 'Talk to Omron'} */}
      </button>
    </div>
  );
}

export default DashboardSpeechToText;
