import React, { useState } from 'react'
import Email from '../../../components/demo/demo-components/email';
import { Col, Container, Row } from 'react-bootstrap';

const AiEmailerHome = () => {
  const [responseFromRasa, setResponseFromRasa] = useState("");

  const handleResponseFromRasa = (response) => {
    console.log("Received response from Rasa:", response);
    setResponseFromRasa(response);
  };
  return (
    <div>          <Email onRasaResponse={handleResponseFromRasa} />
</div>
   
  );
}

export default AiEmailerHome;