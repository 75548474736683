import React from 'react'
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Feature1.css"
const Feature1 = () => {
  return (
    <Container className="my-container  py-5">
      <Row className="align-items-center my-5 justify-content-center">
        {/* Left column with responsive image */}
        <Col
          lg={6}
          md={12}
          // className="d-sm-none d-md-block"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="image-column">
            <Image src="1.png" alt="Responsive Image" fluid />
          </div>
        </Col>
        {/* Right column with title and text */}
        <Col lg={6} md={12} data-aos="fade-up" data-aos-duration="2000">
          <div className="text-column">
            <h2 className="mb-4 " style={{color:''}}>
              Advertising & Marketing
            </h2>
            <p className="text-start" style={{fontSize:"20px"}}>
              Verbalyze revolutionize customer engagement by seamlessly
              integrating into marketing content, responding instantly to
              inquiries.
            </p>
            <div className="text-start">
              <ul className="headul">
                <li>Actively classifies leads for targeted outreach.</li>
                <li>
                  Provides real-time assistance during webinars or events.
                </li>
                <li>Elevating the interactive experience.</li>
              </ul>
            </div>
          </div>
        </Col>
        {/* <Col lg={6} md={12} className="d-none d-sm-block d-md-none">
          <div className="image-column">
            <Image src="wave.png" alt="Responsive Image" fluid />
          </div>
        </Col> */}
      </Row>
    </Container>
  );
}

export default Feature1
