import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Header.css"
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { logoBlue } from "../../assets";



function Header() {
   const scrollToComponent = () => {
     const component = document.getElementById("meet-calender");
     if (component) {
       component.scrollIntoView({ behavior: "smooth" });
     }
   };
  // const handleClick = () => {
  //   if (scrollToComponent) {
  //     // If scrollToComponent is provided, scroll to that component
  //     const element = document.getElementById(scrollToComponent);
  //     if (element) {
  //       element.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }
  // };
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="navbar__bg navbar-dark p-2 "
        >
          <Container fluid>
            <Link to="/">
              <img
                src={logoBlue}
                alt=""
                style={{ width: "220px", height: "60px", margin: "10px" }}
              />
            </Link>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              style={{ color: "white" }}
              className="navbg"
            >
              <Offcanvas.Header closeButton style={{ color: "white" }}>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  // style={{ marginLeft: "20px" }}
                >
                  <img
                    src="whitelogo.png"
                    alt=""
                    style={{ width: "200px", height: "50px", margin: "10px" }}
                  />{" "}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 ">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                  <Link to="/solutions" className="nav-link">
                    Solutions
                  </Link>
                  <Link to="/subscription" className="nav-link">
                    Features
                  </Link>
                  <Link
                    to="/about-us"
                    className="nav-link"
                    // style={{ marginLeft: "20px" }}
                  >
                    About us
                  </Link>
                  <Link
                    to="/meet"
                    className="mx-2 meetusnavlink"
                    onClick={scrollToComponent}
                    style={{
                      border: "none ",
                      borderRadius: "30px",
                      // marginLeft: "20px",
                      padding: "10px 19px",
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    Meet us
                  </Link>
                  <Link
                    to="https://admin.verbalyze.in/"
                    className="mx-2"
                    style={{
                      border: "1px solid #0A369D",
                      borderRadius: "30px",
                      // marginLeft: "20px",
                      background: "none",
                      padding: "10px 15px",
                      textDecoration: "none",
                      color: "#0A369D",
                    }}
                  >
                    Login
                  </Link>

                  {/* <Nav.Link
                    href="#action2"
                    className="nav-link1 "
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "30px",
                      fontStyle: "Montserrat",
                      // marginLeft: "20px",
                      // padding: "10px 20px",
                    }}
                  >
                    Get a demo
                  </Nav.Link> */}

                  {/* <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
                {/* <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form> */}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Header;
