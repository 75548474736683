// import React, { useState, useEffect } from "react";
// import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import "./texttospeech.css"

// const TextToSpeechConverter = ({ onAudioPlaybackComplete }) => {
//   const [textToConvert, setTextToConvert] = useState('');

//   const handleButtonClick = async () => {
//     try {
//       // const ttsEndpoint = "https://maletts.azurewebsites.net/male_tts";
//       const ttsEndpoint =
//         "https://verbalyzefemaletts.azurewebsites.net/female_tts";

//       const queryParams = new URLSearchParams({
//         message: textToConvert,
//       });

//       const response = await fetch(`${ttsEndpoint}?${queryParams.toString()}`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch TTS audio: ${response.status}`);
//       }

//       const audioBlob = await response.blob();
//       const audioUrl = URL.createObjectURL(audioBlob);

//       const audio = new Audio(audioUrl);
//       audio.addEventListener("ended", () => {
//         URL.revokeObjectURL(audioUrl);
//         onAudioPlaybackComplete();
//       });
//       audio.play();
//     } catch (error) {
//       console.error("Error during TTS request:", error);
//     }
//   };

//   return (
//     // <div className="">
//     //   <textarea
//     //     value={textToConvert}
//     //     onChange={(e) => setTextToConvert(e.target.value)}
//     //     placeholder="Enter text to convert..."
//     //   />
//     //   <button
//     //     id="invisible-button"
//     //     onClick={handleButtonClick}
//     //     className="speech-button "
//     //   >
//     //     Convert to Speech
//     //   </button>
//     // </div>
//     <Container>
//       <Row className="mt-4">
//         <Col md={8} className="mx-auto">
//           <Form>
//             <Form.Group controlId="textToConvert">
//               <Form.Control
//                 as="textarea"
//                 value={textToConvert}
//                 onChange={(e) => setTextToConvert(e.target.value)}
//                 style={{
//                   height: "160px",
//                   padding: "20px",
//                   backgroundColor: "gainsboro",
//                 }}
//                 placeholder="Enter text to convert..."
//               />
//             </Form.Group>
//             <Button
//               id="invisible-button"
//               onClick={handleButtonClick}
//               className="speech-button convertbtn mx-auto d-block"
//             >
//               Convert to Speech
//             </Button>
//           </Form>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default TextToSpeechConverter;



// import React, { useState, useEffect } from "react";
// import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import "./texttospeech.css";

// const TextToSpeechConverter = ({ onAudioPlaybackComplete }) => {
//   const [textToConvert, setTextToConvert] = useState("");

//   const handleMaleButtonClick = async () => {
//     try {
//       const ttsEndpoint = "https://maletts.azurewebsites.net/male_tts";

//       const queryParams = new URLSearchParams({
//         message: textToConvert,
//       });

//       const response = await fetch(`${ttsEndpoint}?${queryParams.toString()}`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch TTS audio: ${response.status}`);
//       }

//       const audioBlob = await response.blob();
//       const audioUrl = URL.createObjectURL(audioBlob);

//       const audio = new Audio(audioUrl);
//       audio.addEventListener("ended", () => {
//         URL.revokeObjectURL(audioUrl);
//         onAudioPlaybackComplete();
//       });
//       audio.play();
//     } catch (error) {
//       console.error("Error during TTS request:", error);
//     }
//   };

//   const handleFemaleButtonClick = async () => {
//     try {
//       const ttsEndpoint =
//         "https://verbalyzefemaletts.azurewebsites.net/female_tts";

//       const queryParams = new URLSearchParams({
//         message: textToConvert,
//       });

//       const response = await fetch(`${ttsEndpoint}?${queryParams.toString()}`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch TTS audio: ${response.status}`);
//       }

//       const audioBlob = await response.blob();
//       const audioUrl = URL.createObjectURL(audioBlob);

//       const audio = new Audio(audioUrl);
//       audio.addEventListener("ended", () => {
//         URL.revokeObjectURL(audioUrl);
//         onAudioPlaybackComplete();
//       });
//       audio.play();
//     } catch (error) {
//       console.error("Error during TTS request:", error);
//     }
//   };

//   return (
//     <Container>
//       <Row className="mt-4">
//         <Col md={8} className="mx-auto">
//           <Form>
//             <Form.Group controlId="textToConvert">
//               <Form.Control
//                 as="textarea"
//                 value={textToConvert}
//                 onChange={(e) => setTextToConvert(e.target.value)}
//                 style={{
//                   height: "160px",
//                   padding: "20px",
//                   backgroundColor: "gainsboro",
//                 }}
//                 placeholder="Enter text to convert..."
//               />
//             </Form.Group>
//             <Button
//               onClick={handleMaleButtonClick}
//               className="speech-button convertbtn mx-auto d-block"
//             >
//               Convert to Male Voice
//             </Button>
//             <Button
//               onClick={handleFemaleButtonClick}
//               className="speech-button convertbtn mx-auto d-block"
//             >
//               Convert to Female Voice
//             </Button>
//           </Form>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default TextToSpeechConverter;














// import React, { useState } from "react";
// import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
// import "./texttospeech.css";

// const TextToSpeechConverter = ({ onAudioPlaybackComplete }) => {
//   const [textToConvert, setTextToConvert] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [playing, setPlaying] = useState(false);
//   const [message, setMessage] = useState("");

//   const handleButtonClick = async (voiceType) => {
//     try {
//       let ttsEndpoint = "";
//       if (voiceType === "male") {
//         ttsEndpoint = "https://maletts.azurewebsites.net/male_tts";
//       } else if (voiceType === "female") {
//         ttsEndpoint = "https://verbalyzefemaletts.azurewebsites.net/female_tts";
//       }

//       const queryParams = new URLSearchParams({
//         message: textToConvert,
//       });

//       setLoading(true);
//       const response = await fetch(`${ttsEndpoint}?${queryParams.toString()}`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch TTS audio: ${response.status}`);
//       }

//       const audioBlob = await response.blob();
//       const audioUrl = URL.createObjectURL(audioBlob);

//       const audio = new Audio(audioUrl);
//       audio.addEventListener("play", () => {
//         setPlaying(true);
//         setLoading(false);
//       });
//       audio.addEventListener("ended", () => {
//         URL.revokeObjectURL(audioUrl);
//         onAudioPlaybackComplete();
//         setMessage(`${voiceType} audio playback completed.`);
//         setPlaying(false);
//       });
//       audio.play();
//     } catch (error) {
//       console.error("Error during TTS request:", error);
//       setLoading(false);
//     }
//   };

//   const clearMessage = () => {
//     setMessage("");
//   };

//   return (
//     <Container>
//       <Row className="mt-4">
//         <Col md={10} className="mx-auto">
//           <Form>
//             <Form.Group controlId="textToConvert" className="mb-4">
//               <Form.Control
//                 as="textarea"
//                 value={textToConvert}
//                 onChange={(e) => setTextToConvert(e.target.value)}
//                 style={{
//                   height: "160px",
//                   padding: "20px",
//                   backgroundColor: "gainsboro",
//                 }}
//                 placeholder="Enter text to convert..."
//               />
//             </Form.Group>
//             <Row className="mb-4">
//               <Col className="d-flex justify-content-center">
//                 <Button
//                   onClick={() => {
//                     clearMessage();
//                     handleButtonClick("male");
//                   }}
//                   className="speech-button convertbtn mx-auto"
//                   disabled={loading || playing}
//                 >
//                   Convert to Male Voice
//                 </Button>
//                 <Button
//                   onClick={() => {
//                     clearMessage();
//                     handleButtonClick("female");
//                   }}
//                   className="speech-button convertbtn mx-auto"
//                   disabled={loading || playing}
//                 >
//                   Convert to Female Voice
//                 </Button>
//               </Col>
//             </Row>
//             <Row className="mb-4">
//               <Col className="d-flex justify-content-center">
//                 {loading && (
//                   <>
//                     <Spinner animation="border" role="status" className="mr-2">
//                       <span className="sr-only"></span>
//                     </Spinner>
//                     &nbsp;&nbsp;
//                     <span>Loading...</span>
//                   </>
//                 )}
//               </Col>
//             </Row>
//             <Row className="mb-4">
//               <Col className="d-flex justify-content-center">
//                 {playing && <p>Playing...</p>}
//               </Col>
//             </Row>
//             <Row className="mb-4">
//               <Col className="d-flex justify-content-center">
//                 {message && <p>{message}</p>}
//               </Col>
//             </Row>
//           </Form>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default TextToSpeechConverter;








// import React, { useState } from "react";
// import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
// import "./texttospeech.css";

// const TextToSpeechConverter = ({ onAudioPlaybackComplete }) => {
//   const [textToConvert, setTextToConvert] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [playing, setPlaying] = useState(false);
//   const [message, setMessage] = useState("");

//   const maleLanguages = [
//     "Gujarati",
//     "Hindi",
//     "Bengali",
//     "Marathi",
//     "Telugu",
//     "Tamil",
//     "English",
//     "Arabic",
//     "Spanish",
//     "French",
//     "Russian",
//   ];

//   const femaleLanguages = [
//     "Gujarati",
//     "Devanagari (Hindi)",
//     "Malayalam",
//     "Bengali",
//     "Tamil",
//     "Telugu",
//     "Kannada",
//     "English",
//   ];

//   const handleButtonClick = async (voiceType) => {
//     try {
//       let ttsEndpoint = "";
//       if (voiceType === "male") {
//         ttsEndpoint = "https://maletts.azurewebsites.net/male_tts";
//       } else if (voiceType === "female") {
//         ttsEndpoint = "https://verbalyzefemaletts.azurewebsites.net/female_tts";
//       }

//       const queryParams = new URLSearchParams({
//         message: textToConvert,
//       });

//       setLoading(true);
//       const response = await fetch(`${ttsEndpoint}?${queryParams.toString()}`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch TTS audio: ${response.status}`);
//       }

//       const audioBlob = await response.blob();
//       const audioUrl = URL.createObjectURL(audioBlob);

//       const audio = new Audio(audioUrl);
//       audio.addEventListener("play", () => {
//         setPlaying(true);
//         setLoading(false);
//       });
//       audio.addEventListener("ended", () => {
//         URL.revokeObjectURL(audioUrl);
//         onAudioPlaybackComplete();
//         setMessage(`${voiceType} audio playback completed.`);
//         setPlaying(false);
//       });
//       audio.play();
//     } catch (error) {
//       console.error("Error during TTS request:", error);
//       setLoading(false);
//     }
//   };

//   const clearMessage = () => {
//     setMessage("");
//   };

//   return (
//     <Container>
//       <Row className="mt-4">
//         <Col md={10} className="mx-auto">
//           <Form>
//             <Form.Group controlId="textToConvert" className="mb-4">
//               <Form.Label>Enter text to convert</Form.Label>
//               <Form.Control
//                 as="textarea"
//                 value={textToConvert}
//                 onChange={(e) => setTextToConvert(e.target.value)}
//                 style={{
//                   height: "160px",
//                   padding: "20px",
//                   backgroundColor: "gainsboro",
//                 }}
//                 placeholder={`(${maleLanguages.join(
//                   ", "
//                 )}) for male voice and (${femaleLanguages.join(
//                   ", "
//                 )}) for female voice...`}
//               />
//             </Form.Group>
//             <Row className="mb-4">
//               <Col className="d-flex justify-content-center">
//                 <Button
//                   onClick={() => {
//                     clearMessage();
//                     handleButtonClick("male");
//                   }}
//                   className="speech-button convertbtn mx-auto"
//                   disabled={loading || playing}
//                 >
//                   Convert to Male Voice
//                 </Button>
//                 <Button
//                   onClick={() => {
//                     clearMessage();
//                     handleButtonClick("female");
//                   }}
//                   className="speech-button convertbtn mx-auto"
//                   disabled={loading || playing}
//                 >
//                   Convert to Female Voice
//                 </Button>
//               </Col>
//             </Row>
//             <Row className="mb-4">
//               <Col className="d-flex justify-content-center">
//                 {loading && (
//                   <>
//                     <Spinner animation="border" role="status" className="mr-2">
//                       <span className="sr-only"></span>
//                     </Spinner>&nbsp;&nbsp;
//                     <span>Loading...</span>
//                   </>
//                 )}
//               </Col>
//             </Row>
//             <Row className="mb-4">
//               <Col className="d-flex justify-content-center">
//                 {playing && <p>Playing...</p>}
//               </Col>
//             </Row>
//             <Row className="mb-4">
//               <Col className="d-flex justify-content-center">
//                 {message && <p>{message}</p>}
//               </Col>
//             </Row>
//           </Form>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default TextToSpeechConverter;






import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import "./texttospeech.css";

const TextToSpeechConverter = ({ onAudioPlaybackComplete }) => {
  const [textToConvert, setTextToConvert] = useState("");
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [message, setMessage] = useState("");

  

  const handleButtonClick = async (voiceType) => {
    try {
      let ttsEndpoint = "";
      if (voiceType === "male") {
        ttsEndpoint = "https://maletts.azurewebsites.net/male_tts";
      } else if (voiceType === "female") {
        ttsEndpoint = "https://verbalyzefemaletts.azurewebsites.net/female_tts";
      }

      const queryParams = new URLSearchParams({
        message: textToConvert,
      });

      setLoading(true);
      const response = await fetch(`${ttsEndpoint}?${queryParams.toString()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch TTS audio: ${response.status}`);
      }

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);

      const audio = new Audio(audioUrl);
      audio.addEventListener("play", () => {
        setPlaying(true);
        setLoading(false);
      });
      audio.addEventListener("ended", () => {
        URL.revokeObjectURL(audioUrl);
        onAudioPlaybackComplete();
        setMessage(`${voiceType} audio playback completed.`);
        setPlaying(false);
      });
      audio.play();
    } catch (error) {
      console.error("Error during TTS request:", error);
      setLoading(false);
    }
  };

  const clearMessage = () => {
    setMessage("");
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col md={10} className="mx-auto">

          <Form>
            <Form.Group controlId="textToConvert" className="mb-2">
              <Form.Control
                as="textarea"
                value={textToConvert}
                onChange={(e) => setTextToConvert(e.target.value)}
                style={{
                  height: "140px",
                  padding: "20px",
                  backgroundColor: "gainsboro",
                }}
                placeholder="Enter any text to convert..."
              />
            </Form.Group>
            <Row className="mb-2">
              <Col className="d-flex justify-content-center py-4">
                <Button
                  onClick={() => {
                    clearMessage();
                    handleButtonClick("male");
                  }}
                  className="convertbtn mx-auto"
                  disabled={loading || playing}
                >
                  Convert to Male Voice
                </Button>
                <Button
                  onClick={() => {
                    clearMessage();
                    handleButtonClick("female");
                  }}
                  className="convertbtn mx-auto"
                  disabled={loading || playing}
                >
                  Convert to Female Voice
                </Button>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="d-flex justify-content-center">
                {loading && (
                  <>
                    <Spinner animation="border" role="status" className="mr-2">
                      <span className="sr-only"></span>
                    </Spinner>
                    &nbsp;&nbsp;
                    <span>Loading...</span>
                  </>
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="d-flex justify-content-center">
                {playing && <p>Playing...</p>}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="d-flex justify-content-center">
                {message && <p>{message}</p>}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default TextToSpeechConverter;
