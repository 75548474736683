import "./App.css";
import Login from "./components/auth/login";
import Signup from "./components/auth/Signup";
import Subscription from "./pages/subscription/subs";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/aboutus/about";
import Home from "./pages/Home/Home";
import Solutions from "./pages/solutions/solutions";
import Policy from "./pages/policy/privacyPolicy";
import OurServices from "./pages/services/service";
import Stage from "./pages/demo/staging/stage";
import AitalkerStaging from "./pages/demo/Aichatter/staging/stage";
import AichatterHome from "./pages/demo/Aichatter";
import AiChatterStaging from "./pages/demo/Aichatter/staging/stage";
import AiTalkerHome from "./pages/demo/Aitalker";
import AiEmailer from "./pages/Home/aiemailer/AiEmailer";
import AiTalker from "./pages/Home/aitalker/AiTalker";
import AiEmailerStaging from "./pages/demo/Aiemailer/staging/stage";
import AiEmailerHome from "./pages/demo/Aiemailer";
import Calender from "./pages/Home/map";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/meet" element={<Calender />} />


{/* ------------------------ DEMO------------------------------------- */}
        
        <Route path="demo/" element={<Stage />}>
          <Route path="aitalker" element={<AitalkerStaging />}>
            <Route index element={<AiTalkerHome />}></Route>
            <Route path="aitalker-home" element={<AiTalkerHome />}></Route>
          </Route>
          <Route path="aichatter" element={<AiChatterStaging />}>
            <Route index element={<AichatterHome />}></Route>
            <Route path="aichatter-home" element={<AichatterHome />}></Route>
          </Route>
          <Route path="aiemailer" element={<AiEmailerStaging />}>
            <Route index element={<AiEmailerHome />}></Route>
            <Route path="aiemailer-home" element={<AiEmailerHome />}></Route>
          </Route>
          {/* <Route path="chats" element={<ChatsStaging />}>
            <Route index element={<CallIndex />}></Route>
            <Route path="facebooklogin" element={<CallIndex />}></Route>
          </Route>
          <Route path="emails" element={<EmailsStaging />}>
            <Route index element={<Emailindex />}></Route>
            <Route path="home" element={<Emailindex />} />
            <Route path="insights" element={<InsightContent />} />
            <Route path="template" element={<HtmlCodePreview />} />
          </Route> */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
