import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const Calender = () => {
  
  const googleCalendarCode = `
  <!-- Google Calendar Appointment Scheduling begin -->
  <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2y-VtvsmvfxsJh8iEMqsML7jplPFVdFH3ZYecJszGV_0p1KWQuar18QFnMJqsVuSEuR_09KuDy?gv=true" style="border: 0" width="100%" height="600" frameborder="0"></iframe>
  <!-- end Google Calendar Appointment Scheduling -->
  `;

  return (
    <div id="meet-calender" className="bg-white p-4 md:p-6 rounded-lg shadow-md">
      <div className="aspect-w-16 aspect-h-9">
        <div dangerouslySetInnerHTML={{ __html: googleCalendarCode }} />
      </div>
    </div>
  );
};

export default Calender;
