
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./about.css";
import TeamSection from "./team";
import Journey from "./journey";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    // Set scroll position to the top without any scrolling effect
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ backgroundColor: " #140636" }}>
        <Helmet>
          <title>Verbalyze | About Us</title>
          <meta
            name="description"
            content="Uniting AI brilliance with human touch, Verbalyze unlocks exceptional conversations. Our technology deciphers emotions, amplifying business interactions. Committed to meaningful communication, we illuminate the path to impactful engagement."
          />
          {/* Add other meta tags as needed */}
        </Helmet>
        <Header scrollToComponent="meet-calender"/>
        <div className="aboutbg">
          {" "}
          <Container fluid className="text-center my-5 ">
            <Row
              className="justify-content-center my-5"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <Col xs={12} md={7}>
                <h1 style={{ color: "#D9696B" }} className="mb-4">
                  About Us
                </h1>
                <p style={{ color: "gainsboro" }}>
                  Uniting AI brilliance with human touch, Verbalyze unlocks
                  exceptional conversations. Our technology deciphers emotions,
                  amplifying business interactions. Committed to meaningful
                  communication, we illuminate the path to impactful engagement.
                </p>
              </Col>
            </Row>
          </Container>
          {/* Second Container with two columns and a vertical line */}
          <Container className="my-5 translucent-container">
            <Row
              className="justify-content-center py-5"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {/* Left Column with heading */}
              <Col
                xs={12}
                md={5}
                className="text-center border-right "
                style={{ textAlign: "center" }}
              >
                <h2>💡 Our Vision</h2>
              </Col>

              {/* Right Column with paragraph */}
              <Col xs={12} md={5} className="text-center">
                <p style={{ textAlign: "justify" }}>
                  Verbalyze envisions transforming how businesses connect with
                  audiences. With AI as our ally, we're committed to deepening
                  engagement and understanding. Our journey is fueled by
                  innovation, aiming to forge lasting connections that redefine
                  the way brands interact and resonate with their customers.
                </p>
              </Col>
            </Row>
          </Container>
          {/* 3rd Container with two columns and a vertical line */}
          <Container className="my-5 translucent-container">
            <Row
              className="justify-content-center py-5"
              style={{ height: "30vh", textAlign: "center" }}
            >
              {/* Left Column with heading */}
              <Col xs={12} md={5} className="text-center">
                <p className="" style={{ textAlign: "justify" }}>
                  Verbalyze pioneers AI-driven transformation, revolutionizing
                  business-audience connections. We're committed to reshaping
                  engagement, forging meaningful brand-audience bonds
                </p>
              </Col>

              {/* Right Column with paragraph */}
              <Col
                xs={12}
                md={5}
                className="text-center border-right "
                style={{ textAlign: "center" }}
              >
                <h2>🌟 Our Mission</h2>
              </Col>
            </Row>
          </Container>
          {/* <TeamSection /> */}
          <Journey />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default About;
