import React, { useState, useEffect } from "react";
// import SpeechToText from "./components/stt";
// import TextToSpeech from "./components/tts";
import "./A.css";
// import { Logo, logo } from "./assets";
// import Chat from "./components/chat";
// import eventEmitter from "./components/eventEmitter";
// import Email from "./components/email";
import { Logo, logo } from "./demoassets";
import SpeechToText from "../../components/demo/demo-components/stt-dashboard";
import Chat from "../../components/demo/demo-components/chat";
import Email from "../../components/demo/demo-components/email";
import eventEmitter from "../../components/demo/demo-components/eventEmitter";
import DashboardSpeechToText from "../../components/demo/demo-components/stt-dashboard";
import TextToSpeech from "../../components/demo/demo-components/tts";
import DashboardTextToSpeech from "../../components/demo/demo-components/tts";
import { logoBlue } from "../../assets";

const A = () => {
  const [responseFromRasa, setResponseFromRasa] = useState("");

  const handleResponseFromRasa = (response) => {
    console.log("Received response from Rasa:", response);
    setResponseFromRasa(response);
  };

  const handleAudioPlaybackComplete = () => {
    eventEmitter.emit("speakingEnd"); // Emit an event when TTS finishes speaking
  };

  const stopRecognition = () => {
    // Add any additional logic needed when stopping recognition
  };

  useEffect(
    () => {
      const recognitionEndHandler = () => {
        setResponseFromRasa(""); // Clear the response when recognition ends
      };

      eventEmitter.on("recognitionEnd", recognitionEndHandler);

      return () => {
        eventEmitter.off("recognitionEnd", recognitionEndHandler);
      };
    },
    [
      /* dependencies */
    ]
  );

  return (
    <div className="app-container">
      {/* <header className="app-header">
        <img src={Logo} alt="Verbalyze Logo" className="verbalyze-logo" />
      </header> */}
      <div className="side-by-side">
        <div className="card-container">
          <img
            src={logoBlue}
            alt="logo"
            className="logo"
            style={{
              width: "14.25rem",
              height: "9.25rem",
              objectFit: "contain",
            }}
          />
          
          <DashboardSpeechToText
            onRasaResponse={handleResponseFromRasa}
            onStopRecognition={stopRecognition}
          />
          <DashboardTextToSpeech
            responseFromRasa={responseFromRasa}
            onAudioPlaybackComplete={handleAudioPlaybackComplete}
          />
        </div>
        {/* <div className="chat-card">
          <Chat onRasaResponse={handleResponseFromRasa} />
        </div> */}
      </div>
      {/* <div>
        <Email onRasaResponse={handleResponseFromRasa} />
      </div> */}
      {/* <footer className="app-footer">
        <p>© 2023 Verbalyze. All rights reserved.</p>
      </footer> */}
    </div>
  );
};

export default A;
