import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Feature1.css";
const Feature3 = () => {
  return (
    <Container className="my-container mt-5">
      <Row className="align-items-center  py-5 justify-content-center">
        {/* Left column with responsive image */}
        <Col
          lg={6}
          md={12}
          data-aos="fade-up"
          data-aos-duration="2000"
          // className="d-sm-none d-md-block"
        >
          <div className="image-column">
            <Image src="5.png" alt="Responsive Image" fluid />
          </div>
        </Col>
        <Col lg={6} md={12} data-aos="fade-up" data-aos-duration="2000">
          <div className="text-column">
            <h2 className="mb-4 " style={{ color: "" }}>
              Customer Support and Troubleshooting
            </h2>
            <p className="text-start" style={{ fontSize: "20px" }}>
              Verbalyze excels in customer support, offering 24/7 assistance to
              address common queries and troubleshoot issues, effectively
              reducing the workload on human support agents.
            </p>
            <div className="text-start">
              <ul className="headul">
                <li>Continuous Assistance.</li>
                <li>Efficient Complaint Management. </li>
                <li>Personalized Recommendations.</li>
              </ul>
            </div>
          </div>
        </Col>
        {/* <Col lg={6} md={12} className="d-none d-sm-block d-md-none">
          <div className="image-column">
            <Image src="wave.png" alt="Responsive Image" fluid />
          </div>
        </Col> */}
      </Row>
    </Container>
  );
};

export default Feature3;
