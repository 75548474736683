// import React from 'react'

// import "./footer.css"
// import { FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa";
// import { Link } from 'react-router-dom';

// const Footer = () => {
//   return (
//     <div>
//       <footer>
//         <div className="footer-top">
//           <div className="container">
//             <div className="row ">
//               <div className="col-md-12 text-center">
//                 {/* <h4>Information</h4> */}
//                 <img
//                   src="whitelogo.png"
//                   alt=""
//                   style={{ width: "300px", height: "80px", margin: "10px" }}
//                   className="img-fluid"
//                 />
//                 <ul className="address1 my-3">
//                   <p>AiVerbalyze Technologies Private Limited</p>
//                 </ul>
//                 <div className="social-container my-3">
//                   {/* <h4>Follow us</h4> */}
//                   {/* <ul className="social-icon">
//                     <li>
//                       <a
//                         href="https://www.instagram.com/"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                       >
//                         <FaInstagram size={29} className="gradientbtn" />
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="https://www.linkedin.com/"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                       >
//                         <FaLinkedin size={29} />
//                       </a>
//                     </li>

//                   </ul> */}
//                   <ul
//                     className="flex mb-4 "
//                     style={{
//                       display: "flex",
//                       listStyle: "none",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <li className="ml-4 icon-li">
//                       <Link
//                         to="https://www.instagram.com/verbalyze.in/"
//                         className="flex justify-center items-center text-orange-500 bg-gray-800 hover:text-gray-100 hover:bg-gradient-to-r from-orange-400 to-orange-600 hover:from-orange-500 hover:to-orange-700 rounded-full transition duration-150 ease-in-out"
//                         aria-label="Instagram"
//                       >
//                         {/* <svg
//                           className="w-16 h-16 fill-current"
//                           viewBox="0 0 32 32"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <circle cx="20.145" cy="11.892" r="1" />
//                           <path d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
//                           <path d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z" />
//                         </svg> */}
//                         <FaInstagram size={39} className="icon" />
//                       </Link>
//                     </li>
//                     <li className="ml-4 icon-li">
//                       <Link
//                         to="https://www.linkedin.com/company/verbalyze/"
//                         aria-label="Linkedin"
//                       >
//                         {/* <svg
//                           className="w-16 h-16 fill-current"
//                           viewBox="0 0 32 32"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
//                         </svg> */}
//                         <FaLinkedin size={39} className="icon" />
//                       </Link>

//                     </li>

//                   </ul>
//                   <Link
//                     to="/policy"
//                     className="nav-link"

//                   >
//                     Privacy Policy
//                   </Link>
//                 </div>
//                 <p>© Copyright Verbalyze 2023. All rights reserved.</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default Footer

// import React from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import "./footer.css";
// import { Link } from "react-router-dom";
// import { FaInstagram, FaLinkedin } from "react-icons/fa";



// const Footer = () => {
//   return (
//     <footer className="footerbox text-light pt-2">
//       <Container className="py-4">
//         <Row className="text-center text-md-left py-4">
//           {/* First Column - Logo */}
//           <Col md={3} className="mb-3 mb-md-0 p-5 pt-0">
//             <img src="whitelogo.png" alt="Logo" className="img-fluid" />
//           </Col>

//           {/* Second Column - NavLinks */}
//           <Col md={3} className="mb-3 mb-md-0">
//             <h5 style={{ color: "#7040d7" }}>Navigation</h5>
//             <ul
//               className="list-unstyled "
//               style={{ textDecoration: "none", color: "white" }}
//             >
//               <li>
//                 <Link className="footerli" to="/">
//                   Home{" "}
//                 </Link>
//               </li>
//               <li>
//                 <Link className="footerli" to="/about-us">
//                   About Us
//                 </Link>
//               </li>
//               <li>
//                 <Link className="footerli" to="https://meet.verbalyze.in/">
//                   Meet Us
//                 </Link>
//               </li>
//               <li>
//                 <Link className="footerli" to="/solutions">
//                   Solutions
//                 </Link>
//               </li>
//               <li>
//                 <Link className="footerli" to="/subscription">
//                   Features
//                 </Link>
//               </li>
//               {/* Add more links as needed */}
//             </ul>
//           </Col>

//           {/* Third Column - Other Lists */}
//           <Col md={3} className="mb-3 mb-md-0">
//             <h5 style={{ color: "#7040d7" }}>Other links</h5>
//             <ul className="list-unstyled">
//               <li>
//                 <Link className="footerli" to="/demo/aitalker">
//                   Demo
//                 </Link>
//               </li>
//               <li>
//                 <Link className="footerli" to="/services">
//                   Our Services
//                 </Link>
//               </li>
//               <li>
//                 <Link className="footerli" to="">
//                   Data Security{" "}
//                 </Link>
//               </li>
//               <li>
//                 <Link className="footerli" to="/policy">
//                   Privacy Policy
//                 </Link>
//               </li>

//               <li>
//                 <Link className="footerli" to="">
//                   Terms & Conditions{" "}
//                 </Link>
//               </li>
//               {/* Add more lists as needed */}
//             </ul>
//           </Col>

//           {/* Fourth Column - Social Links */}
//           <Col md={3} className="mb-3 mb-md-0">
//             <h5 style={{ color: "#7040d7" }}>Social Links</h5>
//             <ul className="list-unstyled">
//               <li>
//                 <Link to="" style={{ color: "white", fontSize: "30px" }}>
//                   <FaInstagram />
//                 </Link>
//               </li>
//               <li>
//                 <Link to="" style={{ color: "white", fontSize: "30px" }}>
//                   <FaLinkedin />
//                 </Link>
//               </li>
//             </ul>
//           </Col>
//         </Row>
//       </Container>
//       {/* Subfooter for Copyright */}
//       <div className="text-center subfooter py-3">
//         <Container className="align-items-center">
//           <p className="m-0">&copy; 2024 Verbalyze. All rights reserved.</p>
//         </Container>
//       </div>
//     </footer>
//   );
// };

// export default Footer;


import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./footer.css";
import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footerbox text-light pt-2">
      <Container className="py-4">
        <Row className="text-center text-md-left py-4">
          {/* First Column - Logo and Social Links */}
          <Col md={3} className="mb-3 mb-md-0 p-5 pt-0">
            <div className="align-items-center  justify-content-md-start mb-4">
              <img src="whitelogo.png" alt="Logo" className="img-fluid mr-3" style={{width:"300px",height:"60px"}} />
              <div className="social-links d-flex justify-content-center pt-5 p-3">
                <Link to="" style={{ color: "white", fontSize: "30px",marginRight:"20px" }}>
                  <FaInstagram />
                </Link>
                <Link to="" style={{ color: "white", fontSize: "30px" }}>
                  <FaLinkedin />
                </Link>
              </div>
            </div>
          </Col>

          {/* Second Column - Empty */}
          <Col md={3} className="mb-3 mb-md-0"></Col>

          {/* Third Column - Navigation Links */}
          <Col md={3} className="mb-3 mb-md-0">
            <h5 style={{ color: "black" }}>Navigation</h5>
            <ul
              className="list-unstyled"
              style={{ textDecoration: "none", color: "white" }}
            >
              <li>
                <Link className="footerli" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="footerli" to="/about-us">
                  About Us
                </Link>
              </li>
              <li>
                <Link className="footerli" to="https://meet.verbalyze.in/">
                  Meet Us
                </Link>
              </li>
              <li>
                <Link className="footerli" to="/solutions">
                  Solutions
                </Link>
              </li>
              <li>
                <Link className="footerli" to="/subscription">
                  Features
                </Link>
              </li>
              {/* Add more links as needed */}
            </ul>
          </Col>

          {/* Fourth Column - Other Links */}
          <Col md={3} className="mb-3 mb-md-0">
            <h5 style={{ color: "black" }}>Other links</h5>
            <ul className="list-unstyled">
              <li>
              <Link className="footerli" to="/">
                {/* <Link className="footerli" to="/demo/aitalker"> */}
                  Demo
                </Link>
              </li>
              <li>
                <Link className="footerli" to="/services">
                  Our Services
                </Link>
              </li>
              <li>
                <Link className="footerli" to="">
                  Data Security
                </Link>
              </li>
              <li>
                <Link className="footerli" to="/policy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="footerli" to="">
                  Terms & Conditions
                </Link>
              </li>
              {/* Add more lists as needed */}
            </ul>
          </Col>
        </Row>
      </Container>
      {/* Subfooter for Copyright */}
      <div className="text-center subfooter py-3">
        <Container>
          <p className="m-0">&copy; 2024 Verbalyze. All rights reserved.</p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
