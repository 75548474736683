// // Sidebar.js
// import React from "react";
// import { Nav } from "react-bootstrap";
// import { Link } from "react-router-dom";

// const Sidebar = () => {
//   return (
//     <Nav defaultActiveKey="/home" className="flex-column">
//       <Nav.Link as={Link} to="/">
//         Dashboard
//       </Nav.Link>
//       <Nav.Link as={Link} to="/table">
//         Table
//       </Nav.Link>
//       <Nav.Link as={Link} to="/pie-chart">
//         Pie Chart
//       </Nav.Link>
//       <Nav.Link as={Link} to="/graph">
//         Graph
//       </Nav.Link>
//       <Nav.Link as={Link} to="/boxes">
//         Boxes
//       </Nav.Link>
//     </Nav>
//   );
// };

// export default Sidebar;


// Sidebar.js

// import React from 'react';
// import { Navbar, Nav } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { BsHouseDoor, BsPeople, BsBox, BsCreditCard, BsGear } from 'react-icons/bs';

// const Sidebar = () => {
//   return (
//     <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
//       {/* Logo */}
//       <Navbar.Brand as={Link} to="/">
//         <img
//           src="logo.png" // Replace with your logo
//           alt="Logo"
//           height="30"
//           className="d-inline-block align-top"
//         />
//       </Navbar.Brand>

//       {/* Toggle button for small screens */}
//       <Navbar.Toggle aria-controls="responsive-navbar-nav" />

//       {/* Navbar links */}
//       <Navbar.Collapse id="responsive-navbar-nav">
//         <Nav className="mr-auto">
//           <Nav.Link as={Link} to="/" className="nav-link-icon">
//             <BsHouseDoor className="nav-icon" /> Home
//           </Nav.Link>
//           <Nav.Link as={Link} to="/customers" className="nav-link-icon">
//             <BsPeople className="nav-icon" /> Customers
//           </Nav.Link>
//           <Nav.Link as={Link} to="/products" className="nav-link-icon">
//             <BsBox className="nav-icon" /> Products
//           </Nav.Link>
//           <Nav.Link as={Link} to="/transactions" className="nav-link-icon">
//             <BsCreditCard className="nav-icon" /> Transactions
//           </Nav.Link>
//           <Nav.Link as={Link} to="/admin" className="nav-link-icon">
//             <BsGear className="nav-icon" /> Admin
//           </Nav.Link>
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//   );
// };

// export default Sidebar;






// import React from 'react'

// const Sidebar = () => {
//   return (
//     <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
//       <a
//         href="/"
//         className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
//       >
//         <span className="fs-5 d-none d-sm-inline">Dashboard</span>
//         {/* <img src="logo.png" alt="" style={{ width: "200px", height: "50px" }} /> */}
//       </a>
//       <ul
//         className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
//         id="menu"
//       >
//         <li className="nav-item">
//           <a href="#" className="nav-link align-middle px-0">
//             <i className="fs-4 bi-house" />{" "}
//             <span className="ms-1 d-none d-sm-inline">Home</span>
//           </a>
//         </li>
//         <li>
//           <a
//             href="#submenu1"
//             data-bs-toggle="collapse"
//             className="nav-link px-0 align-middle"
//           >
//             <i className="fs-4 bi-speedometer2" />{" "}
//             <span className="ms-1 d-none d-sm-inline">Dashboard</span>{" "}
//           </a>
//           <ul
//             className="collapse show nav flex-column ms-1"
//             id="submenu1"
//             data-bs-parent="#menu"
//           >
//             <li className="w-100">
//               <a href="#" className="nav-link px-0">
//                 {" "}
//                 <span className="d-none d-sm-inline">Item</span> 1{" "}
//               </a>
//             </li>
//             <li>
//               <a href="#" className="nav-link px-0">
//                 {" "}
//                 <span className="d-none d-sm-inline">Item</span> 2{" "}
//               </a>
//             </li>
//           </ul>
//         </li>
//         <li>
//           <a href="#" className="nav-link px-0 align-middle">
//             <i className="fs-4 bi-table" />{" "}
//             <span className="ms-1 d-none d-sm-inline">Orders</span>
//           </a>
//         </li>
//         <li>
//           <a
//             href="#submenu2"
//             data-bs-toggle="collapse"
//             className="nav-link px-0 align-middle "
//           >
//             <i className="fs-4 bi-bootstrap" />{" "}
//             <span className="ms-1 d-none d-sm-inline">Bootstrap</span>
//           </a>
//           <ul
//             className="collapse nav flex-column ms-1"
//             id="submenu2"
//             data-bs-parent="#menu"
//           >
//             <li className="w-100">
//               <a href="#" className="nav-link px-0">
//                 {" "}
//                 <span className="d-none d-sm-inline">Item</span> 1
//               </a>
//             </li>
//             <li>
//               <a href="#" className="nav-link px-0">
//                 {" "}
//                 <span className="d-none d-sm-inline">Item</span> 2
//               </a>
//             </li>
//           </ul>
//         </li>
//         <li>
//           <a
//             href="#submenu3"
//             data-bs-toggle="collapse"
//             className="nav-link px-0 align-middle"
//           >
//             <i className="fs-4 bi-grid" />{" "}
//             <span className="ms-1 d-none d-sm-inline">Products</span>{" "}
//           </a>
//           <ul
//             className="collapse nav flex-column ms-1"
//             id="submenu3"
//             data-bs-parent="#menu"
//           >
//             <li className="w-100">
//               <a href="#" className="nav-link px-0">
//                 {" "}
//                 <span className="d-none d-sm-inline">Product</span> 1
//               </a>
//             </li>
//             <li>
//               <a href="#" className="nav-link px-0">
//                 {" "}
//                 <span className="d-none d-sm-inline">Product</span> 2
//               </a>
//             </li>
//             <li>
//               <a href="#" className="nav-link px-0">
//                 {" "}
//                 <span className="d-none d-sm-inline">Product</span> 3
//               </a>
//             </li>
//             <li>
//               <a href="#" className="nav-link px-0">
//                 {" "}
//                 <span className="d-none d-sm-inline">Product</span> 4
//               </a>
//             </li>
//           </ul>
//         </li>
//         <li>
//           <a href="#" className="nav-link px-0 align-middle">
//             <i className="fs-4 bi-people" />{" "}
//             <span className="ms-1 d-none d-sm-inline">Customers</span>{" "}
//           </a>
//         </li>
//       </ul>
//       <hr />
//       <div className="dropdown pb-4">
//         <a
//           href="#"
//           className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
//           id="dropdownUser1"
//           data-bs-toggle="dropdown"
//           aria-expanded="false"
//         >
//           <img
//             src="https://github.com/mdo.png"
//             alt="hugenerd"
//             width={30}
//             height={30}
//             className="rounded-circle"
//           />
//           <span className="d-none d-sm-inline mx-1">loser</span>
//         </a>
//         <ul
//           className="dropdown-menu dropdown-menu-dark text-small shadow"
//           aria-labelledby="dropdownUser1"
//         >
//           <li>
//             <a className="dropdown-item" href="#">
//               New project...
//             </a>
//           </li>
//           <li>
//             <a className="dropdown-item" href="#">
//               Settings
//             </a>
//           </li>
//           <li>
//             <a className="dropdown-item" href="#">
//               Profile
//             </a>
//           </li>
//           <li>
//             <hr className="dropdown-divider" />
//           </li>
//           <li>
//             <a className="dropdown-item" href="#">
//               Sign out
//             </a>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default Sidebar




// Sidebar.js

// Sidebar.js

// Sidebar.js

import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsHouseDoor, BsPeople, BsBox, BsCreditCard, BsGear } from 'react-icons/bs';
import "./Sidebar.css"
import { FaPhone, FaEnvelope, FaComments } from "react-icons/fa";



const Sidebar = (props) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const togglebtn = { color: "white ! important" }; // Set the color for the toggle button

  return (
    <Navbar
      expanded={expanded}
      expand="lg"
      className="h-100 flex-column"
      style={{ minHeight: "100vh" }} // Add padding at the top
    >
      {/* Toggle button for small screens */}
      <Navbar.Toggle
        onClick={handleToggle}
        className="togglebtn"
        style={{ color: "white" }}
      />

      <Navbar.Collapse style={{ flexGrow: 0, padding: "20px" }}>
        <Nav className="flex-column">
          <div className="mb-5">
            <Link to="/">
              {" "}
              <img
                src="/logo.png"
                style={{ width: "150px", height: "40px" }}
                alt=""
              />
            </Link>
          </div>

          <Nav.Link as={Link} className="nav-link-icon">
            <FaPhone className="nav-icon" />
            &nbsp; &nbsp; CALLS
          </Nav.Link>
          <div
            className=" flex-row"
            style={{ paddingLeft: "50px", margin: "0" }}
          >
            <Row>
              {" "}
              <Link to="/demo/aitalker/aitalker-home" className="sublink ">
                Ai Talker
              </Link>
            </Row>
          </div>
          <Nav.Link as={Link} className="nav-link-icon">
            <FaComments className="nav-icon" />
            &nbsp; &nbsp; CHATS
          </Nav.Link>
          <div className=" flex-row" style={{ paddingLeft: "50px" }}>
            {/* <Row>
              {" "}
              <Link to="/chats-home" className="sublink ">
                Home
              </Link>
            </Row> */}
            <Row>
              <Link to="/demo/aichatter/aichatter-home" className="sublink">
                Ai Chatter
              </Link>
            </Row>
            {/* <Row>
              <Link to="/chats-insights" className="sublink">
                Insights
              </Link>
            </Row> */}
          </div>
          <Nav.Link as={Link} className="nav-link-icon">
            <FaEnvelope className="nav-icon" />
            &nbsp; &nbsp; EMAILS
          </Nav.Link>
          <div className=" flex-row" style={{ paddingLeft: "50px" }}>
            <Row>
              {" "}
              <Link to="/demo/aiemailer/aiemailer-home" className="sublink ">
                Ai Emailer
              </Link>
            </Row>
          </div>
          {/* <Nav.Link as={Link} to="/insights" className="nav-link-icon">
            <BsBox className="nav-icon" /> &nbsp; &nbsp; Insights
          </Nav.Link>
          <Nav.Link as={Link} to="/transactions" className="nav-link-icon">
            <BsCreditCard className="nav-icon" /> &nbsp;&nbsp; Transactions
          </Nav.Link>
          <NavDropdown
            title={<BsGear className="nav-icon" />}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item as={Link} to="/admin/users">
              Users
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/admin/settings"
              className="nav-link-icon"
            >
              Settings
            </NavDropdown.Item>
          </NavDropdown> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Sidebar;
