

import React, { useState, useEffect } from "react";
import "./chat2.css";
import { FaPaperPlane } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";


const Chat = ({ onRasaResponse }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      const userMessage = { text: newMessage, user: "chatuser" };
      setChatMessages((prevChatMessages) => [...prevChatMessages, userMessage]);
      setNewMessage("");

      try {
        const requestBody = {
          prompt: newMessage,
          verbalyze_api_key: "ezi10xchat",
          user_id: "client_1",
          convo_type: "chat",
          parameters: {
            temperature: 0.1,
          },
        };

        const response = await fetch(
          "https://swar-samwad-2.azurewebsites.net/webhooks/rest/webhook",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          handleBotResponse(responseData);
        } else {
          handleBotError("Chatbot error");
        }
      } catch (error) {
        handleBotError("Error communicating with chatbot");
        console.error("Error sending request to chatbot:", error);
      }
    }
  };

  const handleBotResponse = (responseData) => {
    if (Array.isArray(responseData)) {
      responseData.forEach((item) => {
        const botMessage = { text: item, user: "chatbot" };
        setChatMessages((prevChatMessages) => [
          ...prevChatMessages,
          botMessage,
        ]);
      });
      onRasaResponse(responseData);
    } else {
      const botMessage = { text: responseData, user: "chatbot" };
      setChatMessages((prevChatMessages) => [...prevChatMessages, botMessage]);
      onRasaResponse([responseData]);
    }
  };

  const handleBotError = (errorMessage) => {
    const error = { text: errorMessage, user: "chatbot" };
    setChatMessages((prevChatMessages) => [...prevChatMessages, error]);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center  pt-5">
      <Row>
        <Col xs={12} sm={8} md={6} lg={4}>

          <div className="chat-container">

            <div className="chat-messages" id="chat-messages-container">
              {chatMessages.map((message, index) => (
                <div key={index} className={`chat-message ${message.user}`}>
                  {message.text}
                </div>
              ))}
            </div>

            <div className="chat-input pt-3">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleInputKeyPress}
                placeholder="Type your message..."
                className="chatinput"
              />
              <button onClick={handleSendMessage} className="btn">
                <FaPaperPlane className="send-icon" />
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Chat;
